import React from 'react'
import SEO from "../components/seo";
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"



export default function testing() {
  return (
    <>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/lazysizes.min_mb7k2f.js"></script>
      <link rel="stylesheet" href="https://res.cloudinary.com/avomeraydin/raw/upload/v1668167002/assets/nftcss/bootstrap.min_zvom8m.css" />
      <link rel="stylesheet" href="https://res.cloudinary.com/avomeraydin/raw/upload/v1668167002/assets/nftcss/font-icons_e2fayb.css" />
      <link rel="stylesheet" href="https://res.cloudinary.com/avomeraydin/raw/upload/v1668167003/assets/nftcss/style_itey4w.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css" integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A==" crossorigin="anonymous" referrerpolicy="no-referrer" />

    <Navbar />
  
    <section class="section-wrap main-content">
      <div class="container">
        <div class="row">

          <div class="col-lg-8 blog__content mb-30">

            <article class="entry">
              <div class="thumb-bg-holder thumb-container thumb-65">
                <img data-src="img/blog/featured_post_img.jpg" src="img/empty.png" class="lazyload" alt="" />
                <a href="single-post.html" class="thumb-url"></a>
                <div class="bottom-gradient"></div>
              </div>

              <div class="thumb-text-holder">
                <a href="single-post.html" class="entry__meta-category">investment</a>   
                <h1 class="thumb-entry-title">
                  <a href="single-post.html">Removing Bitcoin payments from Steam is a smart move by Valve</a>
                </h1>
              </div>
            </article> 

            <section class="section">
              <h3 class="section-title h2">Hot News</h3>
              <div class="row">
                <div class="col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">currency market</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-75">
                          <img data-src="img/blog/post_1.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>

                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title entry__title--medium">
                          <a href="single-post.html">Bitcoin Fees: Possible Solutions and Scenarios</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">education</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-75">
                          <img data-src="img/blog/post_2.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>

                    <div class="entry__body">
                      <div class="entry__header">                        
                        <h2 class="entry__title entry__title--medium">
                          <a href="single-post.html">Navigating The ICO Gold Rush – 4 Critical ICO Evaluation Factors</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
              </div> 
            </section> 

            <section>
              <h3 class="section-title h2">Recent News</h3>
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">education</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_3.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">How to Read a Blockchain Explorer</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">investment</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_4.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">Growth Hacking Your ICO – 7 Steps to Successful Token Sale</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">ICO’s</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_5.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">Litecoin Mining: Scrypt Algorithm for a Leading Coin</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">mining</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_6.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">How to Buy Litecoin: Get your First Digital Silver</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">reviews</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_7.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">ChronoBank: What's in the Near Future?</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="col-lg-4 col-md-6">
                  <article class="entry">
                    <a href="categories.html" class="entry__meta-category">investment</a>
                    <div class="entry__img-holder">
                      <a href="single-post.html">
                        <div class="thumb-container thumb-60">
                          <img data-src="img/blog/post_8.jpg" src="img/empty.png" class="entry__img lazyload" alt="" />
                        </div>
                      </a>
                    </div>
                    <div class="entry__body">
                      <div class="entry__header">
                        <h2 class="entry__title">
                          <a href="single-post.html">The Simple Way to Get on Board with Cryptocurrency</a>
                        </h2>
                      </div>
                    </div>
                  </article>
                </div>
              </div> 
            </section> 

          </div> 
          <aside class="col-lg-4 sidebar sidebar--right">

            <div class="widget widget-featured-icos">
              <h4 class="widget-title">Öne Çıkan NFT Projeleri</h4>
              <ul class="widget-featured-icos__list">
                <li class="widget-featured-icos__item">
                  <a href="https://opensea.io/collection/ninja-squad-official" target="_blank" class="widget-featured-icos__url">
                    <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1668372090/assets/images/41c1d9fcb97d5abcf2f56a934e973a12_1_n770t4.svg" alt="" />
                    <span class="widget-featured-icos__title">Ninja Squad</span>
                    <span class="widget-featured-icos__launch">Başlangıç:</span>
                    <span class="widget-featured-icos__time">2021 Kasım</span>
                  </a>
                </li>
                <li class="widget-featured-icos__item">
                  <a href="https://opensea.io/collection/gobznft" target="_blank" class="widget-featured-icos__url">
                    <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1668372096/assets/images/gobz_1_x6qixd.svg" alt="" />
                    <span class="widget-featured-icos__title">GobzNFT</span>
                    <span class="widget-featured-icos__launch">Başlangıç</span>
                    <span class="widget-featured-icos__time">2021 Aralık</span>
                  </a>
                </li>
                <li class="widget-featured-icos__item">
                  <a href="https://opensea.io/collection/coniun-pass" target="_blank" class="widget-featured-icos__url">
                    <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1668372100/assets/images/coniun-pass-thumb_1_okakci.svg" alt="" />
                    <span class="widget-featured-icos__title">ConiunPass</span>
                    <span class="widget-featured-icos__launch">Başlangıç</span>
                    <span class="widget-featured-icos__time">2022 Mart</span>
                  </a>
                </li>
              </ul>
            </div> 

            <div class="widget widget-socials">
              <div class="socials justify-content-center">
                <a class="social social-twitter" href="https://twitter.com/omerjpeg" title="twitter" target="_blank" aria-label="twitter">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </div>
            </div> 

            <div class="widget widget-popular-posts">
              <h4 class="widget-title">Popular Posts</h4>

              <ul class="widget-popular-posts__list">
                <li class="widget-popular-posts__item">
                  <article>
                    <div class="widget-popular-posts__entry">
                      <a href="categories.html" class="entry__meta-category">mining</a>
                      <h3 class="widget-popular-posts__entry-title">
                        <a href="single-post.html">Bitcoin Fees: Possible Solutions and Scenarios</a>
                      </h3>
                    </div>
                  </article>
                </li>
                <li class="widget-popular-posts__item">
                  <article>
                    <div class="widget-popular-posts__entry">
                      <a href="categories.html" class="entry__meta-category">mining</a>
                      <h3 class="widget-popular-posts__entry-title">
                        <a href="single-post.html">Bitcoin Fees: Possible Solutions and Scenarios</a>
                      </h3>
                    </div>
                  </article>
                </li>
                <li class="widget-popular-posts__item">
                  <article>
                    <div class="widget-popular-posts__entry">
                      <a href="categories.html" class="entry__meta-category">mining</a>
                      <h3 class="widget-popular-posts__entry-title">
                        <a href="single-post.html">Bitcoin Fees: Possible Solutions and Scenarios</a>
                      </h3>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </aside>

        </div> 
      </div> 
    </section>


    <Footer />


      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166786/assets/nftjs/jquery.min_a04wg0.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/bootstrap.min_bxp8cm.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/easing.min_pxjw13.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/jquery.marquee.min_gy7b2d.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/imagesloaded.pkgd.min_pbajkk.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166785/assets/nftjs/isotope.pkgd.min_tah2vn.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166786/assets/nftjs/owl-carousel.min_f1pgw4.js"></script> 
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166786/assets/nftjs/modernizr.min_bxhyqa.js"></script>
      <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1668166786/assets/nftjs/scripts_xwszsn.js"></script>
    </>

  )
}
